import React from 'react';
import back from '../images/icons/icon-back-chevron.svg';

const BackButton = ({ onClick, text }) => {
  return (
    <button
      name="link"
      className="relative z-10 flex gap-1.5 justify-start flex-nowrap tracking-widest"
      onClick={() => {
        if (onClick) onClick();
        else typeof window !== 'undefined' && window?.history.back();
      }}
    >
      <img className="h-2.5 w-2.5 my-auto" src={back} alt="back" />
      <span className="text-xs font-bold uppercase text-brand-blue-4">{text}</span>
    </button>
  );
};

export default BackButton;
