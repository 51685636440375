import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { SignupForm } from '../components/signup';
import SfIllustrationWhite from '../images/core/sf-illustration-white.svg';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import BackButton from './backButton';
import Language from './content/language';
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';

const IntroTopSection = ({
  pathname,
  title,
  subTitle,
  date,
  dateModified,
  heroImage,
  heroImageAlt,
  creditLink,
  creditLinkText,
  introComponents,
  url,
  backTo,
  backText,
  shareText,
  signupText,
  showLang,
  currentLang,
  usedLangs,
}) => (
  <div className="pb-0 sm:pb-5 bg-white -mx-5 px-5">
    <div className="w-full max-w-3xl mx-auto">
      {showLang && (
        <div className="w-full max-w-7xl mx-auto">
          <Language
            pathname={pathname}
            currentLang={currentLang}
            usedLangs={usedLangs}
            className={'relative justify-end -mb-4 lg:mb-3 z-10'}
          />
        </div>
      )}
      <div className="mb-2.5">
        {backTo && (
          <BackButton onClick={() => navigate(backTo || '/')} text={backText || 'GO BACK'} />
        )}
      </div>
      <div className="border-b border-slate-200 pb-5" data-top-section>
        <h1 className="text-left text-2xl font-black capitalize mb-2.5 text-brand-blue-5 pt-2 sm:text-5xl sm:font-black sm:pt-0">
          {title}
        </h1>
        {subTitle ? (
          <h2 className="text-xl sm:text-2xl sm:font-semibold text-gray-600 m-0">{subTitle}</h2>
        ) : null}
        {/* date ? (
          <h4 className="text-left text-xs italic font-light mb-0 text-gray-700">
            Published {date}
          </h4>
        ) : null */}
        {dateModified ? (
          <span className="text-left text-xs italic font-light mb-0 mt-0 text-gray-700">
            Last Updated: {dateModified}
          </span>
        ) : null}
      </div>
      <div className="w-full max-w-3xl mx-auto">
        {heroImage ? (
          <div className="w-full">
            <GatsbyImage
              image={heroImage}
              alt={heroImageAlt ? heroImageAlt : title}
              className="shadow-lg object-cover mt-0 min-w-full"
            />
          </div>
        ) : null}
        {creditLink ? (
          <p className="mt-4 text-center text-base text-gray-400">
            Photo Credit:{' '}
            <a href={creditLink} className="underline">
              {creditLinkText}
            </a>
          </p>
        ) : (
          ''
        )}
      </div>
      {introComponents && (
        <div className="text-sm text-gray-700 font-light mx-0 my-5 whitespace-pre-wrap [&_p_a]:text-brand-blue-4 [&_p_a]:font-bold [&_p_a]:z-10 sm:my-0 sm:mb-6 sm:mt-9">
          {introComponents}
        </div>
      )}
      {signupText && (
        <div className="flex flex-col justify-center items-center p-4">
          <span className="mb-2">
            <b>
              <ReactMarkdown>{signupText}</ReactMarkdown>
            </b>
          </span>
          <SignupForm formStyle="home" />
        </div>
      )}
    </div>
  </div>
);

export default IntroTopSection;
