import React, { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { Link } from 'gatsby';
import getLang, { langs } from '../../utils/getLang';

const Language = ({ pathname, currentLang, usedLangs, className }) => {
  // enable get used laguages form mdx as props OR use all the predefined languages at utils/getLang
  const currentLangs = usedLangs || langs.map((lang) => lang.type);
  // get current path "client-side from location" and remove any extra slash "/" at the end
  const currentPath = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
  // enable get the current used page laguage form mdx slug "server-side" as props OR use location path "client-side"
  const activeLang = currentLang || getLang(currentPath);
  const basePath = activeLang === 'en' ? currentPath : currentPath.replace(`/${activeLang}`, '');
  // convert langs array to key-value pairs object relative to type
  let langsObject = {};
  langs.forEach((ele) => {
    langsObject = { ...langsObject, [ele.type]: { text: ele.text, path: ele.to } };
  });

  return (
    <div className={twMerge('text-base flex gap-2 ml-auto', className)}>
      {currentLangs.map((language, index) => (
        <Fragment key={`languages_${index}`}>
          {language != activeLang ? (
            <Link
              to={basePath + langsObject[language].path}
              className="text-brand-blue-4 hover:text-blue-800 no-underline"
            >
              {langsObject[language].text}
            </Link>
          ) : (
            <span className="text-brand-blue-5 font-bold">{langsObject[language].text}</span>
          )}
          {index != currentLangs.length - 1 && <span>/</span>}
        </Fragment>
      ))}
    </div>
  );
};

export default Language;
