import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import IntroTopSection from '../components/introTopSection';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import BarChart from '../components/pulse/barChart';
import Divider from '../components/pulse/divider';
import Doughnut from '../components/pulse/doughnut';
import DoughnutWrapper from '../components/pulse/doughnutWrapper';
import InfoItem from '../components/pulse/infoItem';
import LeftColumn from '../components/pulse/leftColumn';
import LineChart from '../components/pulse/lineChart';
import List from '../components/list';
import Question from '../components/pulse/question';
import QuestionHeader from '../components/pulse/questionHeader';
import RightColumn from '../components/pulse/rightColumn';
import Section from '../components/pulse/section';
import StickyContent from '../components/pulse/stickyContent';
import PulseHeader from '../components/pulse/pulseHeader';
import { SignupForm } from '../components/signup';

import fm3 from '../images/pulse/FM3-logo.png';

const PulseTemplate = ({ location = {}, data = {} }) => {
  const { mdx = {}, site = {} } = data;
  const {
    slug: slug,
    frontmatter: {
      featured_image: featuredImage,
      hide_featured_image: hideFeaturedImage,
      hide_top: hideTop,
      open_graph_image: openGraphImage,
      featured_image_credit_link: creditLinkFromData,
      featured_image_credit: creditLinkTextFromData,
      date_modified: dateModifiedFromData,
      title,
      description,
      hero_image_alt: heroImageAlt,
      intro_text: introText,
    } = {},
    body,
  } = mdx;

  const introComponents = introText != '' ? <ReactMarkdown>{introText}</ReactMarkdown> : null;
  const heroImage = featuredImage && !hideFeaturedImage ? getImage(featuredImage) : null;
  const showTop = !hideTop;
  const openGraphImageResized = openGraphImage?.childImageSharp.resize || null;
  const creditLink = creditLinkFromData || null;
  const creditLinkText = creditLinkTextFromData || null;
  const dateModified = dateModifiedFromData;
  const Fm3 = () => <img src={fm3} width={331} height={97} alt="fm3" />;

  const shortcodes = {
    LineChart,
    Fm3,
    BarChart,
    Divider,
    Doughnut,
    DoughnutWrapper,
    InfoItem,
    LeftColumn,
    List,
    Question,
    QuestionHeader,
    RightColumn,
    Section,
    StickyContent,
    PulseHeader,
    SignupForm,
  };
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image={openGraphImageResized}
        pathname={location.pathname}
      />

      <div className="bg-brand-blue-1 mt-10 px-5 [&_strong]:font-bold">
        {showTop ? (
          <IntroTopSection
            pathname={location.pathname}
            title={title}
            dateModified={dateModified}
            heroImage={heroImage}
            heroImageAlt={heroImageAlt}
            creditLink={creditLink}
            creditLinkText={creditLinkText}
            introComponents={introComponents}
            url={`https://growsf.org/${slug}`}
            backText="View all GrowSF Pulse polls"
            shareText="Share this Pulse"
            backTo={'/pulse'}
            signupText={null}
          />
        ) : (
          ''
        )}
        <div className="bg-brand-blue-1 overflow-x-clip -mx-5 px-5 pb-12">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </Layout>
  );
};

export default PulseTemplate;
