export const langs = [
  { type: 'en', text: 'EN', to: '' },
  { type: 'zh', text: '中文', to: '/zh' },
  { type: 'fr', text: 'Fr', to: '/fr' },
  { type: 'es', text: 'ES', to: '/es' },
  { type: 'fil', text: 'FIL', to: '/fil' },
];
const getLang = (path) => {
  const currentPath = path.endsWith('/') ? path.substring(0, path.length - 1) : path;
  let lang = currentPath.split('/').pop() || '';
  return langs.find((ele) => ele.type === lang) ? lang : 'en';
};
export default getLang;
